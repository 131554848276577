<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3">
                <div class="w-100">
                    <div>
                        <router-link :to="{ name: 'Reports' }" class="font14 b" style="color: #132c8c;">&lt; Report</router-link>
                        <div class="pv4 f3 b">Expense Reports</div>
                    </div>
                    <div class="flex gap8 justify-between w-100 pb3" style="align-items: flex-end">
                        <div class="w-30">
                            <label class="block f500 font14 colorLabel" for="report-to">From</label>
                            <input
                                type="date"
                                class="trialDate w-100"
                                name="startDate"
                                v-model="state.startDate"
                                id="report-to"
                            />
                        </div>
                        <div class="w-30">
                            <label class="block f500 font14 colorLabel" for="report-from">To</label>
                            <input type="date" class="trialDate w-100" name="endDate" v-model="state.endDate" id="report-from" />
                        </div>

                        <select name="comparison" id="filter" v-model="state.filter" class="w-30 trialDate">
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Annually">Annually</option>
                        </select>
                        <button class="filter card-boxx" title="Refresh" @click="handleExpenseRecords">
                            <!-- filter_list.svg -->
                            <img :src="require('@/assets/images/Reload.svg')" alt="filter icon" />
                        </button>
                    </div>
                    <!-- <div class="reportFilter w-100">
                        <button class="filter card-boxx" title="Refresh" @click="handleExpenseRecords">
                           
                            <img :src="require('@/assets/images/Reload.svg')" alt="filter icon" />
                        </button>
                        <div class="filter mr3 ml3 w-12-l">
                            <div class="tc flex items-center">
                                <div><img src="@/assets/images/filter_list.svg" alt="filter icon" /></div>
                                <div class="pl2 tl">Filter</div>
                            </div>
                        </div>
                        <button class="flex gap16 items-center pv2">
                            <span><img :src="require('@/assets/images/search3.svg')" /></span>
                            <span class="pl2 tl">Search</span>
                        </button>
                    </div> -->
                    <div class="scrollmenu w-100 center">
                        <table class="w-100 tl mt3" cellspacing="0">
                            <thead>
                                <!--  -->
                                <tr class="font-w1 pt5 headerColor">
                                    <th class="pv3 pr3 tl">Date</th>
                                    <th v-for="date in state.allDates" :key="date" class="pv3 pr3 tr">
                                        <span v-if="state.filter === 'Daily'" class="">{{ formatDate(date) }}</span>
                                        <span v-else class="">{{ date }}</span>
                                    </th>
                                    <th class="pv3 tr pr3 b">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(result, index) in state.result" :key="index" class="font-w1">
                                    <td class="pv3 pr3 tl b">{{ expenseSidebarOptions[result.data[0].type] }}</td>
                                    <td v-for="(iResult, index) in result.data" :key="index" class="pv3 pr3 tr">
                                        {{ formatAmount(iResult.amount, $store.state.Settings?.currency) }}
                                    </td>

                                    <td class="pv3 pr3 tr">
                                        {{ formatAmount(result.sum, $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                                <tr class="font-w1">
                                    <td class="pv3 pb3 pr3 tl">Total</td>
                                    <td
                                        v-for="cal in state.allKeyCalculations.keyCalculation"
                                        :key="cal"
                                        class="pv3 pb3 pr3 tr"
                                    >
                                        {{ formatAmount(cal.amount, $store.state.Settings?.currency) }}
                                    </td>
                                    <td class="pv3 pb3 tr pr3">
                                        {{ formatAmount(state.allKeyCalculations.sum, $store.state.Settings?.currency) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="reportFilter mt4">
                        <button class="flex items-center">
                            <span>Export</span><span><img :src="require('@/assets/images/white-caret.svg')" /></span>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>
<script>
import AppWrapper from '@/layout/AppWrapper'
import OrgData from '@/components/OrgData'
import { computed, onMounted, reactive, watch } from 'vue'
import { formatAmount, formatDateInLocal, formatDate, expenseSidebarOptions, formatDateFilter, downloadCSV } from '@/utils/lib'
import { useStore } from 'vuex'

export default {
    name: 'ExpenseReportNew',
    components: { AppWrapper, OrgData },

    setup() {
        const store = useStore()
        const orgData = computed(() => store.state.Auth.userData)
        const report = computed(() => store.state.Reporting.expenseReports)

        const state = reactive({
            startDate: '',
            endDate: '',
            filter: 'Daily',
            result: [],
            subTypes: [],
            allDates: [],
            allKeyCalculations: [],
        })

        const month = new Date().getMonth()
        const year = new Date().getFullYear()
        const day = new Date().getDate()

        const tableHead = {
            monthly: 'Month',
            daily: 'Day',
            weekly: 'Week',
            annually: 'Year',
        }

        const downloadFile = () => {
            downloadCSV(state.result)
        }

        const handleExpenseRecords = () => {
            if (state.filter.toLowerCase() === 'daily') {
                state.startDate = new Date(year, month, 1)
                state.endDate = new Date(year, month, day)

                //Daily: 1st of current Month - Current date
                // console.log('daily')
            } else if (state.filter.toLowerCase() === 'weekly') {
                // 1st week of the Year to current week. Week starts from Monday//
                state.startDate = new Date(year, 0, 1)
                state.endDate = new Date(year, month, day)
                // console.log('weekly')
            } else if (state.filter.toLowerCase() === 'monthly') {
                // Monthly: Jan of the current year - Current month
                state.startDate = new Date(year, 0, 1)
                state.endDate = new Date(year, month, day)
                // console.log('monthly')
            } else if (state.filter.toLowerCase() === 'annually') {
                // 2021 to current Year
                state.startDate = new Date(2021, 0, 1)
                state.endDate = new Date(year, month, day)
                // console.log('yearly')
            }
            if (state.startDate > state.endDate) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Start date cannot be greater than end date',
                    status: false,
                })
            }

            if (!state.filter) {
                return store.dispatch('Alert/setAlert', {
                    message: 'Filter is not selected',
                    status: false,
                })
            }

            store.dispatch('Reporting/getExpenseReport', {
                startDate: state.startDate,
                endDate: state.endDate,
                filter: state.filter,
            })

            //console.log(state.startDate, state.endDate, state.filter, 'state.startDate, state.endDate, state.filter')
        }
        onMounted(() => {
            const startDate = new Date(year, 0, 1)
            const endDate = new Date(year, month, day)
            const filter = 'Daily'

            //console.log(startDate, endDate, filter, 'startdate, endDate, filter')

            const query = {
                startDate: startDate,
                endDate: endDate,
                filter: filter,
            }

            state.startDate = formatDateFilter(startDate)
            state.endDate = formatDateFilter(endDate)

            store.dispatch('Reporting/getExpenseReport', query)
        })

        watch(
            () => report.value,
            () => {
                if (report.value && report.value.result && report.value.result.length > 0) {
                    state.result = report.value.result
                    state.allDates = report.value.allkeys
                    state.subTypes = report.value.subTypes
                    state.allKeyCalculations = report.value.allKeyCalculations
                }
            }
        )

        return {
            orgData,
            report,
            formatDateInLocal,
            tableHead,
            state,
            handleExpenseRecords,
            expenseSidebarOptions,
            formatDateFilter,
            downloadCSV,
            downloadFile,
            formatDate,
            formatAmount,
        }
    },
}
</script>
<style>
.gap16 {
    gap: 16px;
}
@media screen and (max-width: 905px) {
}
</style>
